import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { CartContext } from './CartContext';
import { useContext } from 'react';
import { images } from '../../constants';

function ProductCard(props) { // props.product is the product we are selling
    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);
    return (
        <Card className="bg-dark text-white">
            <Card.Img variant="top" src={images.logo} alt='cart image' />
            <Card.Body>
                <Card.Title className='p__opensans'>{product.title}</Card.Title>
                <Card.Text className='p__opensans'>{product.duration}</Card.Text>
                <Card.Text className='p__opensans'>{product.from} {product.price}€</Card.Text>
                { productQuantity > 0 ?
                    <>
                        <Form as={Row}>
                            <Form.Label column="true" sm="6">In Cart: {productQuantity}</Form.Label>
                            <Col sm="6">
                                <Button variant='dark' onClick={() => cart.addOneToCart(product.id)} >+</Button>
                                <Button variant='dark' onClick={() => cart.removeOneFromCart(product.id)}  >-</Button>
                            </Col>
                        </Form>
                        <button className='custom__button' variant='danger' onClick={() => cart.deleteFromCart(product.id)}>Remove item</button>
                    </>
                    :
                    <button href="https://lift.bio/morielement" target="_blank" rel="noreferrer" className='custom__button energy_button'><a href="https://lift.bio/morielement" target="_blank" rel="noreferrer">Book a Session</a></button>
                    // <button variant="warning" className='custom__button' onClick={() => cart.addOneToCart(product.id)}>Add To Cart</button>
                }
            </Card.Body>
        </Card>
    )
}

export default ProductCard;