import React from 'react'
import TrackVisibility from 'react-on-screen';
import { images } from '../../constants';
import { SubHeading } from '../../components';
import "./praxis.css"

const Praxis = () => {
  return (
    <div className='app__fire app__bg app__wrapper section__padding' id="fire">
           <div className='app__wrapper_info'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}>
             
              <h1 className='headtext__cormorant'>Serenity Awaits: A Tranquil Space for Healing and Balance</h1>
              
            </div>}
        </TrackVisibility>
      </div>
      <div className='app__wrapper_img3'>
        <TrackVisibility partialVisibility once>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeInLeft" : ""}>
              <img src={images.px1} alt="fire" />
              <div className='app__chef-content'>
                <p className='p__opensans'>Our healing space is designed for your comfort and relaxation. From calming lighting to soothing sounds, every detail is curated to create a peaceful environment where you can unwind and focus on restoring balance. Whether you're here for pain relief or stress reduction, our tranquil space enhances the healing benefits of Yumeiho therapy. We look forward to welcoming you.</p>
                      </div>
                      <button href="https://lift.bio/morielement" target="_blank" rel="noreferrer" className='custom__button energy_button'><a href="https://lift.bio/morielement" target="_blank" rel="noreferrer">Book a Session</a></button>
            </div>}
        </TrackVisibility>
      </div>
 
    </div>
  );
}

export default Praxis