import React from 'react';
import { AboutUs, Music, Massage, Footer, Header, Intro, Fire, Yoga, Energy, Store, Event } from './container';
import { Navbar } from './components';
import './App.css';
import { Container } from 'react-bootstrap';
import CartProvider from './container/Store/CartContext';
import Music2 from './container/Dj/Music2';
import Praxis from './container/Praxis/praxis';



const App = () => (
  <>
 
    {/* <CartProvider> */}
      <Navbar />
      <Header />
      <AboutUs />
      <Massage />
      <Praxis />
      <Yoga />
      <Energy />
      <Event />
      <Music2 />
      <Music />
      <Fire />
      <Intro />
      <Container>
        {/* <BrowserRouter>
          <Routes>
            <Route path="success" element={<Success />} />
            <Route path="cancel" element={<Cancel />} />
          </Routes>
        </BrowserRouter> */}
      </Container>
      <Store></Store>
    {/* </CartProvider> */}
    <Footer />
  </>
);

export default App;
