import React,{useState,useContext} from 'react';
import { GiHamburgerMenu} from 'react-icons/gi'
import { FiTarget } from 'react-icons/fi'
import images from '../../constants/images'
import './Navbar.css';
import { CartContext } from '../../container/Store/CartContext';
import CartProduct from '../../container/Store/CartProduct';
import {Modal, Button} from "react-bootstrap"


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cart = useContext(CartContext);
  const productCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return (
  <nav className='app__navbar'>
    <div className='app__navbar-logo'>
      <a href="/#home"><img src={images.logo} alt="app logo" /></a>
    </div>

    <ul className='app__navbar-links'>
    <li className='p__opensans'><a href="#home">Home</a></li>
      <li className='p__opensans'><a href="#about">About me</a></li>
      <li className='p__opensans'><a href="#massage">Yumeiho Massage Therapy</a></li>
      <li className='p__opensans'><a href="#yoga">Yoga</a></li>
      <li className='p__opensans'><a href="#energy">Energy Therapy</a></li>
      <li className='p__opensans'><a href="#event">Festival & Event Management</a></li>
      <li className='p__opensans'><a href="#music">Music & Fire Performance</a></li>
    </ul>

    <div className='app__navbar-login'>
      
    {productCount > 0 ? <button
            className="custom__button"
            variant="success"
            onClick={handleShow}
          
          >
             Cart ({productCount} Items)
          </button>: ""}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Your Booking</Modal.Title>

          </Modal.Header>
          <Modal.Body>
            {productCount > 0 ? (
              <>
                <p>Items in your cart:</p>
                {cart.items.map((currentProduct, idx) => (
                  <CartProduct
                    key={idx}
                    id={currentProduct.id}
                    quantity={currentProduct.quantity}
                  >
                    {" "}
                  </CartProduct>
                ))}
                <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>
                <button className="custom__button" onClick={()=> window.open("https://form.jotform.com/231252553810347")}>
                  Complete Order!
                </button>
              </>
            ) : (
              <h1>There are no itmes in your cart!</h1>
            )}
          </Modal.Body>
        </Modal>
        {productCount > 0 ? "": <a href="#booking"  rel="noreferrer"  style={{color:"white"}} className='p__opensans'>Book a Session</a>}
       
      <div />
      <a href="https://lift.bio/morielement" target="_blank" rel="noreferrer" style={{color:"white"}} className='p__opensans'>Contact</a>
    </div>
    <div className='app__navbar-smallscreen'>
      <GiHamburgerMenu color='#fff' fontSize={27} onClick={()=> setToggleMenu(true)}/>
      {toggleMenu && (
      <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
        <FiTarget fontSize={27} className="overlay__close" onClick={()=> setToggleMenu(false)} />
      <ul className='app__navbar-smallscreen_links' onClick={()=> setToggleMenu(false)} >
      <li className='p__opensans'><a href="#home">Home</a></li>
      <li className='p__opensans'><a href="#about">About me</a></li>
      <li className='p__opensans'><a href="#massage">Yumeiho Massage Therapy</a></li>
      <li className='p__opensans'><a href="#yoga">Yoga</a></li>
      <li className='p__opensans'><a href="#energy">Energy Therapy</a></li>
      <li className='p__opensans'><a href="#event">Festival & Event Management</a></li>
      <li className='p__opensans'><a href="#music">Music & Fire Performance</a></li>
      
    </ul>
    </div>)}
    </div>
  </nav>
)};

export default Navbar;

